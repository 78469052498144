import { VStack } from "@chakra-ui/react";

import biBg from "../assets/bi.png";
import info from "../assets/info.png";

const PageMid = ({ children, extraStyles }) => (
  <VStack
    pt={{ vxs: "2", xxxs: "2" }}
    pb={{ vxs: "1", xxxs: "2" }}
    pr={{ vxs: "3", xxxs: "4" }}
    pl={{ vxs: "3", xxxs: "4" }}
   // height="80vh"
   bgColor='#D3D3D3'
    bgPos="center"
    bgRepeat="no-repeat"
    bgSize="cover"
    overflowY="hidden"
    overflowX="hidden"
    {...extraStyles}
  >
    {children}
  </VStack>
);

export default PageMid;
