import {
  Button,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  Card,
  CardBody,
  Flex,
  Center,
  Box,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import PageMid from "./components/PageMid";
import PageTop from "./components/PageTop";
import main_image from "./assets/new_background.jpg";
import michelin from "./assets/michelin.png";
import cdlhit from "./assets/CDL-Scanner-HIT-Logos-01.png";
import enrollment from "./assets/enrollment-first-logo-stacked-white-01.png";
import scanface from "./assets/Scan-Face.png";
import quotestart from "./assets/Quotes-01.png";
import quoteend from "./assets/Quotes-02.png";
import ac from "./assets/info.png";
import truck from "./assets/Truckicon-01.png";
import michelinblue from "./assets/Michelin_Corporate_Logo___color_3.png";
import press from "./assets/PressReleaseIcon-01.png";
import galileo from "./assets/Galileo_Logo.png";
import freightwaves from "./assets/freightwaveslogo.png";
import enrollblue from "./assets/Enrollment-First-stacked-logo-two-blue.png";
function App() {
  return (
    <>
      <PageMid
        extraStyles={{ p: 3, fontSize: 16, spacing: 4, textAlign: "justify" }}
      >
        {/* <Card>
          <CardBody
            className="bkgnew"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <Flex direction="row">
              <Flex direction="column" style={{ flexBasis: "70%" }} p="20">
                <Flex mt="18">
                  <Image
                    className="michelin-sponsor"
                    style={{ padding: "10px", maxWidth: "300px", width: "9vw" }}
                    src={cdlhit}
                    alt="Michelin Sponsor"
                  />
                  <Image
                    className="michelin-sponsor"
                    style={{ padding: "10px", maxWidth: "300px", width: "9vw" }}
                    src={enrollment}
                    alt="Michelin Sponsor"
                  />
                </Flex>
                <Flex direction="column" mt="7">
                  <Heading
                    as="h3"
                    size="3xl"
                    noOfLines={2}
                    textAlign="left"
                    color="white"
                  >
                    Health Monitoring On the Road.
                  </Heading>
                  <Link href="https://chakra-ui.com" isExternal>
                    <Button
                      mt="20"
                      style={{
                        maxWidth: "300px",
                        height: "50px",
                        width: "300px",
                        marginTop: "10vh",
                        background: "white",
                        borderRadius: "24px",
                      }}
                    >
                      <u>Click to Order Today</u>
                    </Button>
                  </Link>
                </Flex>
              </Flex>
              <Flex direction="row">
                <Image
                  className="michelin-sponsor"
                  style={{ padding: "8%" }}
                  src={scanface}
                  alt="Michelin Sponsor"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card> */}
        <Card>
          <CardBody
            className="bkgnew"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "80%",
              maxWidth: "1280px",
              flexDirection: "column",
            }}
          >
            <Flex direction={{ base: "column", lg: "row" }} alignItems="center">
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                mb={{ base: "4", lg: "0" }}
                flex={{ base: "none", lg: "1" }}
              >
                <Image
                  className="michelin-sponsor"
                  style={{ maxWidth: "300px", width: "100%" }}
                  src={scanface}
                  alt="Michelin Sponsor"
                />
              </Flex>
              <Flex
                direction="column"
                style={{ flexBasis: "70%" }}
                p={{ base: "4", lg: "20" }}
              >
                <Flex
                  mt="18"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Image
                    className="michelin-sponsor"
                    style={{
                      padding: "10px",
                      maxWidth: "300px",
                      width: "100%",
                    }}
                    src={cdlhit}
                    alt="Michelin Sponsor"
                  />
                  <Link href="https://enrollfirst.com/" isExternal>
                    <Image
                      className="michelin-sponsor"
                      style={{
                        padding: "10px",
                        maxWidth: "300px",
                        width: "100%",
                      }}
                      src={enrollment}
                      alt="Enrollment Sponsor"
                    />
                  </Link>
                </Flex>
                <Flex
                  direction="column"
                  mt="7"
                  alignItems={{ base: "center", lg: "flex-start" }}
                >
                  <Heading
                    as="h3"
                    size="3xl"
                    noOfLines={3}
                    textAlign={{ base: "center", lg: "left" }}
                    color="white"
                  >
                    Health Monitoring On the Road.
                  </Heading>
                  <Link
                    href="https://cdlscanner.enrollfirst.com/app"
                    isExternal
                  >
                    <Button
                      mt="8"
                      style={{
                        maxWidth: "300px",
                        height: "50px",
                        width: "100%",
                        background: "white",
                        borderRadius: "24px",
                      }}
                    >
                      <u>Click to Order Today</u>
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        {/*
        <Card>
          <CardBody
            className="roadimg"
            style={{
              textAlign: "center",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <Flex style={{ margin: "10px" }}>
              <Box w="50%" pt="5" pb="5">
                <Heading
                  as="h3"
                  size="2xl"
                  pl="10"
                  noOfLines={2}
                  textAlign="left"
                  className="customred"
                  style={{ fontWeight: "normal" }}
                >
                  Technology Revolutionized
                </Heading>

                <Flex
                  pl="10"
                  pr="10"
                  style={{ textAlign: "left", marginTop: "10px" }}
                >
                  The CDL Health Scanner is partnered with NuraLogix to deliver
                  this groundbreaking preventive tool.
                  <br />
                  <br />
                  The goal of this tool is to help drivers track their health on
                  the road by using their phone's camera to scan their face and
                  analyze key vital signs.
                  <br />
                  <br />
                  This unique, driver-centric system by Anura uses a patented
                  technology called Transdermal Optical Imaging.
                </Flex>
              </Box>
              <Box w="50%" pt="5" pb="5">
                <Heading
                  as="h3"
                  size="2xl"
                  noOfLines={2}
                  textAlign="left"
                  className="customred"
                  style={{ fontWeight: "normal" }}
                >
                  How It Works
                </Heading>

                <Flex direction="row">
                  <Flex direction="column" style={{ flexBasis: "15%" }}>
                    <Heading as="h3" size="2xl" mt="4">
                      1.
                    </Heading>
                    <Heading as="h3" size="2xl" style={{ marginTop: "120px" }}>
                      2.
                    </Heading>
                  </Flex>
                  <Flex
                    direction="column"
                    style={{
                      textAlign: "left",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    Using their smart phone camera, the driver scans their face
                    and records vitals.
                    <br />
                    <OrderedList mb="4" style={{ fontWeight: "bold" }}>
                      <ListItem>Blood Pressure</ListItem>
                      <ListItem>Heart Rate</ListItem>
                      <ListItem>BMI</ListItem>
                      <ListItem>Respiratory Rate</ListItem>
                    </OrderedList>
                    Post-scan, drivers have access to education resources to
                    make simple changes that can help protect their license and
                    livelihood:
                    <br />
                    <OrderedList style={{ fontWeight: "bold" }} mb="4">
                      <ListItem>Diabetes Resources</ListItem>
                      <ListItem>Sleep Apnea Resource</ListItem>
                      <ListItem>Higi Health Check Station Locations</ListItem>
                    </OrderedList>
                    <Text className="customred" style={{ fontWeight: "bold" }}>
                      Drivers can prepare for the DOT Exam with this powerful
                      tool!
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </CardBody>
        </Card> */}
        <Card>
          <CardBody
            className="roadimg"
            style={{
              textAlign: "center",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 pt-5 pb-5">
                <h3 className="text-2xl leading-7 font-normal customred">
                  Technology Revolutionized
                </h3>
                <div className="px-5 mt-5 md:px-10 md:mt-10 text-left">
                  <p>
                    The CDL Health Scanner is partnered with NuraLogix to
                    deliver this groundbreaking preventive tool.
                  </p>
                  <br />
                  <p>
                    The goal of this tool is to help drivers track their health
                    on the road by using their phone's camera to scan their face
                    and analyze key vital signs.
                  </p>
                  <br />
                  <p>
                    This unique, driver-centric system by Anura uses a patented
                    technology called Transdermal Optical Imaging.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 pt-5 pb-5">
                <h3 className="text-2xl leading-7 font-normal customred">
                  How It Works
                </h3>
                <div className="flex flex-row">
                  <div className="flex flex-col justify-start items-center w-1/5">
                    {/* <h3 className="text-2xl mt-4 md:mt-0">1.</h3>
                    <h3 className="text-2xl mt-4 md:mt-0">2.</h3> */}
                  </div>
                  <div className="text-left px-5 mt-5 md:px-10 md:mt-10">
                    <ol class="space-y-4 list-decimal">
                      <li>
                        Using their smartphone camera, the driver scans their
                        face and records vitals.
                        <ul className="list-disc list inside pl-6 font-bold">
                          <li>Blood Pressure</li>
                          <li>Heart Rate</li>
                          <li>BMI</li>
                          <li>Respiratory Rate</li>
                        </ul>
                      </li>
                      <li>
                        Post-scan, drivers have access to educational resources
                        to make simple changes that can help protect their
                        license and livelihood:
                        <ul className="list-disc pl-6 mb-4 font-bold">
                          <li>Diabetes Resources</li>
                          <li>Sleep Apnea Resource</li>
                          <li>Higi Health Check Station Locations</li>
                        </ul>
                      </li>
                    </ol>
                    <p className="customred font-bold">
                      Drivers can prepare for the DOT Exam with this powerful
                      tool!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        {/*
        <Card>
          <CardBody
            className="bkgnew"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <Flex direction="column">
              <Heading
                as="h5"
                size="2xl"
                pt="20"
                noOfLines={2}
                textAlign="center"
                color="white"
              >
                Testimonials
              </Heading>

              <Flex direction="row" p="5" color="white">
                <Flex mt="5" pt="20" pb="20" style={{ maxWidth: "33%" }}>
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    src={quotestart}
                    alt="quotes"
                  />
                  <Text p="5">
                    I downloaded the app this morning and got my results and I
                    think this could help a lot of truckers to check and keep
                    track of their health.
                    <br />
                    <br />
                    Jim Fowler
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
                <Flex mt="5" pt="20" pb="20" style={{ maxWidth: "33%" }}>
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    src={quotestart}
                    alt="quotes"
                  />
                  <Text p="5">
                    I've been using the Scanner since it was first available -
                    great tool for drivers for prevention. Easy to use and
                    accurate.
                    <br />
                    <br />
                    Randy Wheaton
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
                <Flex mt="5" pt="20" pb="20" style={{ maxWidth: "33%" }}>
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    src={quotestart}
                    alt="quotes"
                  />
                  <Text p="5">
                    The CDL Scanner is a valuable health tool for drivers to
                    manage their health - I use it.
                    <br />
                    <br />
                    Ellen Voie
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card> */}
        <Card>
          <CardBody
            className="bkgnew"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <Flex direction="column">
              <Heading
                as="h5"
                size="2xl"
                // pt="20"
                pt={{ base: 0, md: 5 }}
                noOfLines={2}
                textAlign="center"
                color="white"
              >
                Testimonials
              </Heading>

              <Flex
                direction={{ base: "column", md: "row" }}
                p={{ base: 0, md: 5 }}
                color="white"
              >
                <Flex
                  mt="5"
                  pt={{ base: 0, md: 20 }}
                  pb={{ base: 0, md: 20 }}
                  className="testibox"
                >
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    display={{ base: "none", md: "block" }}
                    src={quotestart}
                    alt="quotes"
                  />
                  <Text p="5" align="left" fontSize={{ base: "xl", md: "md" }}>
                    I downloaded the app this morning and got my results and I
                    think this could help a lot of truckers to check and keep
                    track of their health.
                    <br />
                    <br />
                    Jim Fowler
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    display={{ base: "none", md: "block" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
                <Flex
                  mt="5"
                  pt={{ base: 0, md: 20 }}
                  pb={{ base: 0, md: 20 }}
                  className="testibox"
                >
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    display={{ base: "none", md: "block" }}
                    src={quotestart}
                    alt="quotes"
                  />
                  <Text p="5" align="left" fontSize={{ base: "xl", md: "md" }}>
                    I've been using the Scanner since it was first available -
                    great tool for drivers for prevention. Easy to use and
                    accurate.
                    <br />
                    <br />
                    Randy Wheaton
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    display={{ base: "none", md: "block" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
                <Flex
                  mt="5"
                  pt={{ base: 0, md: 20 }}
                  pb={{ base: 0, md: 20 }}
                  className="testibox"
                >
                  <Image
                    style={{ alignSelf: "flex-start" }}
                    src={quotestart}
                    display={{ base: "none", md: "block" }}
                    alt="quotes"
                  />
                  <Text p="5" align="left" fontSize={{ base: "xl", md: "md" }}>
                    The CDL Scanner is a valuable health tool for drivers to
                    manage their health - I use it.
                    <br />
                    <br />
                    Ellen Voie
                  </Text>
                  <Image
                    style={{ alignSelf: "flex-end" }}
                    display={{ base: "none", md: "block" }}
                    src={quoteend}
                    alt="quotes"
                  />
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        {/* <Card>
          <CardBody
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "80%",
              maxWidth: "1280px",
            }}
          >
            <Flex direction="column">
              <Flex direction="row">
                <Heading
                  as="h5"
                  size="2xl"
                  ml="16"
                  pt="10"
                  noOfLines={2}
                  textAlign="left"
                  className="customred"
                >
                  Quote Your Fleet
                </Heading>
                <Image
                  style={{ alignSelf: "center", width: "120px" }}
                  src={truck}
                  alt="truck"
                />
                <Link
                  href="https://chakra-ui.com"
                  isExternal
                  style={{ marginLeft: "12vw" }}
                >
                  <Button
                    style={{
                      maxWidth: "300px",
                      height: "50px",
                      width: "320px",
                      marginTop: "4vh",
                      color: "white",
                      background: "#bd350b",
                      borderRadius: "40px",
                    }}
                  >
                    <u>Request a Group Quote</u>
                  </Button>
                </Link>
              </Flex>

              <Flex
                direction="row"
                color="black"
                ml="16"
                pt="10"
                pb="10"
                w="68%"
              >
                CDL Health Scanner is available for individual drivers and
                fleets. To quote CDL Health Scanner for your whole fleet, email
                sales@enrollfirst.com or call 866-951-8418 M-F 8am to 5pm EST.
              </Flex>
            </Flex>
          </CardBody>
        </Card> */}
        <Card>
          <CardBody
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap", // Allow content to wrap on smaller screens
              maxWidth: "1280px",
            }}
          >
            <Flex direction={{ base: "column", md: "row" }} align="center">
              <Heading
                as="h5"
                size="xl"
                ml={{ base: 0, md: "16px" }}
                noOfLines={2}
                textAlign="center"
                className="customred"
              >
                Quote Your Fleet
              </Heading>
              <Image
                style={{
                  alignSelf: "center",
                  width: "120px",
                  margin: "0 28px",
                }}
                src={truck}
                alt="truck"
              />
              {/* <Link href="mailto:sales@enrollfirst.com" isExternal> */}
              <Link
                href="https://support.enrollfirst.com/contact?quote"
                isExternal
              >
                <Button
                  style={{
                    maxWidth: "300px",
                    height: "50px",
                    width: "100%",
                    marginTop: "4vh",
                    color: "white",
                    background: "#bd350b",
                    borderRadius: "40px",
                  }}
                >
                  <u>Request a Group Quote</u>
                </Button>
              </Link>
            </Flex>

            <Flex
              direction="column"
              color="black"
              ml={{ base: 0, md: "16px" }}
              mr={{ base: 0, md: "16px" }}
              pt="10"
              pb="10"
              w="100%"
              textAlign="center"
            >
              CDL Health Scanner is available for individual drivers and fleets.
              For any questions, call our sales team at 866-951-8418 M-F 8am to
              5pm EST.
            </Flex>
          </CardBody>
        </Card>

        <Flex
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap" // Allow content to wrap on smaller screens
          maxWidth="1280px"
          className="widthbottom"
          bg="white"
        >
          <Flex
            className="flex-col md:flex-row w-full"
            pt="10"
            pb="10"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box
              w={{ base: "full", md: "1/2" }}
              className="flex flex-col justify-center mx-auto md:mx-0"
              mb="5"
            >
              <Heading
                as="h5"
                size="2xl"
                ml="4"
                noOfLines={2}
                textAlign="left"
                className="customred"
                fontWeight="normal"
              >
                Partnership
              </Heading>

              <Flex ml="4" mt="10" justifyContent="flex-start">
                <Link href="https://enrollfirst.com/" isExternal>
                  <Image
                    className="self-center w-32 md:w-40"
                    src={enrollblue}
                    alt="enroll"
                  />
                </Link>
                <Link href="https://www.michelinman.com/" isExternal>
                  <Image
                    className="self-center w-32 md:w-40"
                    src={michelinblue}
                    alt="michelin"
                  />
                </Link>
              </Flex>
            </Box>

            <Box
              w={{ base: "full", md: "1/2" }}
              className="flex flex-col justify-center mx-auto md:mx-0"
              mt={{ base: "10", md: "0" }}
            >
              <Heading
                as="h5"
                size="2xl"
                noOfLines={2}
                textAlign="left"
                className="customred"
                fontWeight="normal"
              >
                As Featured In
              </Heading>

              <Flex
                className="flex-wrap"
                mt="10"
                justifyContent="flex-start"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Link
                  href="https://www.prweb.com/releases/2023/03/prweb19251846.htm"
                  isExternal
                >
                  <Image
                    className="self-center w-20 md:w-24 mb-2 mr-4"
                    src={press}
                    alt="enroll"
                  />
                </Link>
                <Link
                  href="https://www.linkedin.com/posts/bob-perry-9766ba18_health-interview-galileo-activity-7043681273902497792-_mOG/?utm_source=share&utm_medium=member_desktop"
                  isExternal
                >
                  <Image
                    className="self-center w-32 md:w-40 mb-2 mr-4"
                    src={galileo}
                    alt="michelin"
                  />
                </Link>
                <Link
                  href="https://www.freightwaves.com/news/sleeping-on-the-tracks-truck-parking-and-the-200k-dime-heist-wtt"
                  isExternal
                >
                  <Image
                    className="self-center w-48 md:w-80"
                    src={freightwaves}
                    alt="michelin"
                  />
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Flex>

        {/* <div className="info">
          THIS PRODUCT WILL NOT BE AVAILABLE UNTIL EARLY JANUARY 2023
        </div> */}
      </PageMid>
    </>
  );
}

export default App;
